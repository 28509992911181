<script>
  import { format } from '../utils';
  import { onMount } from "svelte";
  import { enquiry, step } from "../stores";
  import Restart from "../icons/Restart.svelte";
  import Step from "../core/Step.svelte";
  import BackButton from "../core/BackButton.svelte";
  import NextButton from "../core/NextButton.svelte";
  import StaticInput from "../core/StaticInput.svelte";
  import Tabs from "../core/Tabs.svelte";
  import TabHeading from "../core/TabHeading.svelte";
  import Tooling from "../tabs/Tooling.svelte";
  import Fluids from "../tabs/Fluids.svelte";
  import Total from "../tabs/Total.svelte";
  import axios from 'axios';

  // Props
  export let errors = {};
  export let errorMessage = null;

  // Currencies
  const currencies = [
    {code: 'GBP', symbol: '£'},
    {code: 'USD', symbol: '$'},
    {code: 'EUR', symbol: '€'},
  ];

  // Init
  let selectedCurrency = currencies[0];
  let data;
  let yourProduct;
  let currentTab = 0;
  let hasCalculated = false;
  let chosenProduct = $enquiry.variant;
  let calculationUrl = false;

  //temp, best to make use of the env variable APP_URL
  const currentDomain = document.location.origin; 

  // Tabs
  const tabs = [Tooling, Fluids, Total];

  // ToolingerrorMessage
  let monthlyToolingSpend;
  let reichertWearTestResult;

  // Fluids
  let costPerIbc;
  let rocolCostPerIbc = chosenProduct.cost_per_ibc;
  let rocolDilutionRatio = chosenProduct.dilution_ratio;
  let numberOfIbc;
  let correctionFactor;
  let refractometerReading;

  // Populate with enquiry data if available
  if ($enquiry.calculations) {
    let calculation = $enquiry.calculations[0];
    if (calculation) {
      yourProduct = calculation.name;
      reichertWearTestResult = calculation.client_reichert_wear_test_result;
      monthlyToolingSpend = calculation.client_monthly_tooling_spend;
      refractometerReading = calculation.client_refractometer_reading;
      correctionFactor = calculation.client_correction_factor;
      costPerIbc = calculation.client_cost_per_ibc;
      numberOfIbc = calculation.client_number_of_ibcs;
      calculate(false);
    }
  }

  setCurrency($enquiry.currency);

  $: potentialToolingSpendReduction = format((chosenProduct.reichert_wear_percentage ? reichertWearTestResult : 0) - chosenProduct.reichert_wear_percentage, 1);
  $: toolingSaving = Number(monthlyToolingSpend * potentialToolingSpendReduction);
  $: dillutionRatio = 100 / (refractometerReading * correctionFactor);
  $: dilltionRatioFormatted = Number.isNaN(dillutionRatio) ? "" : `${dillutionRatio.toFixed(1)}:1`;
  $: mixedDillution = format(dillutionRatio * chosenProduct.volume.vol_1 + chosenProduct.volume.vol_1);
  $: inSump = format(costPerIbc / mixedDillution);
  $: annualisedMixedEmultion = format(numberOfIbc * mixedDillution);
  $: annualCost = format(numberOfIbc * costPerIbc);
  $: comparisonMixedDillution = format(chosenProduct.dilution_ratio * chosenProduct.volume.vol_2 + chosenProduct.volume.vol_2);
  $: comparisonInSump = format(chosenProduct.cost_per_ibc / comparisonMixedDillution);
  $: comparisonAnnualisedMixedEmultion = format(chosenProduct.number_of_ibc * comparisonMixedDillution);
  $: comparisonAnnualCost = format(chosenProduct.number_of_ibc * chosenProduct.cost_per_ibc);
  $: fluidSaving = annualCost - comparisonAnnualCost;
  $: totalSaving = format(fluidSaving + toolingSaving * 12);

  function calculate(add = true) {
    axios.post(`/api/enquires/${add ? 'add' : 'get'}/calculation`, {
      enquiry_id: $enquiry.enquiry_id,
      name: yourProduct,
      reichertWearTestResult,
      monthlyToolingSpend,
      refractometerReading,
      correctionFactor,
      costPerIbc,
      rocolCostPerIbc,
      rocolDilutionRatio,
      numberOfIbc
    })
      .then(res => {
        currentTab = 1;
        if (add) {
          enquiry.set(res.data.data);
        }

        const calculation = add ? res.data.data.calculations[0] : res.data;
        hasCalculated = true;
        calculationUrl = `${currentDomain}/pdf/default/${$enquiry.enquiry_id}/${res.data.data.calculations[0].id}`;
        errorMessage = null;;
        errors = {};

        data = {
          tooling: {
            reichertWearTestResult: calculation.client_reichert_wear_test_result,
            potentialToolingSpendReduction: calculation.potential_tool_spend_reduction,
            savings: calculation.tooling_saving * 12
          },
          fluids: {
            costPerIbc: {
              you: calculation.client_cost_per_ibc,
              rocol: calculation.rocol_cost_per_ibc
            },
            mixedDillution: {
              you: calculation.client_mixed_dilution,
              rocol: calculation.rocol_mixed_dilution
            },
            numberOfIbc: {
              you: calculation.client_number_of_ibcs,
              rocol: calculation.rocol_number_of_ibcs
            },
            dillutionRatio: calculation.rocol_dilution_ratio,
            annualisedMixedEmultion: calculation.rocol_annualised_mixed_emulsion,
            annualisedCost: calculation.rocol_annual_cost,
            savings: calculation.fluids_saving
          },
          total: {
            savings: calculation.total_saving
          }
        };
      })
      .catch(err => {
        data = {};
        hasCalculated = false;
        errorMessage = err.response.data.message;

        if (err.response.data.errors) {
          errors = err.response.data.errors;
        } else {
          console.log(err);
          errors = {};
        }
      })
  }

  function finish() {
    step.update(n => n + 1)
  }

  function setCurrency(currency) {
    console.log('input: ', currency);
    if (typeof currency == 'string') {
      currencies.forEach(function(c, i) {
        if (c.code == currency) {
          selectedCurrency = currencies[i];
        }
      });
    } else {
      selectedCurrency = currency;
    }
  }

  function changeCurrency(currency) {
    axios.post(`/api/enquires/currency`, {
      enquiry_id: $enquiry.enquiry_id,
      currency: currency,
    }).then(res => {
      setCurrency(currency);
    });
  }

  function windowPrint() {
      window.print();
  }
</script>

<Step>
  <div
    class="flex flex-col-reverse md:flex-row md:justify-between items-start
    md:items-center"
    slot="header">
    <h1 class="text-5xl font-bold leading-none mt-4 md:mt-0">Customer usage</h1>
    <span>



      <!-- ANALOG: Removed after parallax handover, previous chromium implementation has bugs so we are using the build in PDF dowload in modern browsers -->
      <!-- <button 
        on:click={windowPrint}
        class={`${!hasCalculated ? 'bg-gray-200 cursor-not-allowed' : 'bg-brand-orange'} text-white text-sm rounded-lg p-4 inline-flex items-center leading-none`}
        disabled={!hasCalculated}
        >
          Print PDF
      </button> -->

      <a 
        href="{hasCalculated ? calculationUrl : '#'}" disabled={!hasCalculated} 
        target="{hasCalculated ? '_blank' : ''}"
        class={`${!hasCalculated ? 'bg-gray-200 cursor-not-allowed' : 'bg-brand-orange'} text-white text-sm rounded-lg p-4 inline-flex items-center leading-none`}
      >
        Print PDF
      </a>


      <BackButton text="Back" on:click={() => step.update(() => 3)} />
      <!-- <NextButton text="Finish and Export" on:click={finish} disabled={!hasCalculated} /> -->
    </span>
  </div>
  <div class="md:flex">
    <div class="md:w-5/12 p-8 border-r border-gray-200">
      <div class="mb-6">
        <h2 class="font-bold text-xl mb-2">Currency</h2>
        <ul class="flex">
          {#each currencies as currency}
            <li on:click={() => changeCurrency(currency)} class={'flex-grow text-center font-bold cursor-pointer' + (currency.code == selectedCurrency.code ? ' text-brand-orange' : '')}>{currency.symbol}</li>
          {/each}
        </ul>
      </div>

      <div class="mb-6">
        <h2 class="font-bold text-xl mb-2">Customer Data</h2>
        <StaticInput
          bind:value={yourProduct}
          classList="mb-2"
          name="your-product">
          Your product
        </StaticInput>
      </div>

      <div class="mb-6">
        <h2 class="font-bold text-xl mb-2">Tooling</h2>
        <StaticInput
          bind:value={reichertWearTestResult}
          error={errors.reichertWearTestResult}
          classList="mb-2"
          name="reichert-wear-test-result">
          Reichert Wear Test Result
          <span class="text-brand-orange">(% Wear)</span>
        </StaticInput>
        <StaticInput
          bind:value={potentialToolingSpendReduction}
          classList="mb-2"
          name="potential-tooling-spend-reduction"
          disabled="true"
          value="20.0">
          Potential Tooling Spend Reduction
          <span class="text-brand-orange">(%)</span>
        </StaticInput>
        <StaticInput
          bind:value={monthlyToolingSpend}
          error={errors.monthlyToolingSpend}
          classList="mb-2"
          name="mothly-tooling-spend">
          Monthly Tooling Spend
        </StaticInput>
      </div>

      <div class="mb-6">
        <h2 class="font-bold text-xl mb-2">Cutting Fluid</h2>
        <StaticInput
          bind:value={refractometerReading}
          error={errors.refractometerReading}
          classList="mb-2"
          name="refractometer-reading">
          Refractometer Reading
          <span class="text-brand-orange">(% Top Up Dilution)</span>
        </StaticInput>
        <StaticInput
          bind:value={correctionFactor}
          error={errors.correctionFactor}
          classList="mb-2"
          name="correction-factor">
          Correction Factor
          <span class="text-brand-orange">(If unkown, enter 1)</span>
        </StaticInput>
        <StaticInput
          bind:value={costPerIbc}
          error={errors.costPerIbc}
          classList="mb-2"
          name="cost-per-ibc">
          Cost per Unit
          <span class="text-brand-orange">({selectedCurrency.symbol} / Unit)</span>
        </StaticInput>

        <StaticInput
          bind:value={dilltionRatioFormatted}
          classList="mb-2"
          name="dillution-ratio"
          disabled="true">
          Dilution Ratio
          <span class="text-brand-orange">(X:1)</span>
        </StaticInput>
        <StaticInput
          bind:value={mixedDillution}
          classList="mb-2"
          name="mixed-dillution"
          disabled="true"
          value="17,667">
          Mixed Dilution
          <span class="text-brand-orange">(L / Unit)</span>
        </StaticInput>
        <StaticInput
          bind:value={inSump}
          classList="mb-2"
          name="in-sump"
          disabled="true"
          value="0.21">
          In Sump
          <span class="text-brand-orange">({selectedCurrency.symbol} / L)</span>
        </StaticInput>
        <StaticInput
          bind:value={numberOfIbc}
          error={errors.numberOfIbc}
          classList="mb-2"
          name="number-of-ibc">
          Number of Units
          <span class="text-brand-orange">(Units / Year)</span>
        </StaticInput>
        <StaticInput
          bind:value={annualisedMixedEmultion}
          classList="mb-2"
          name="annualised-mixed-emultion"
          disabled="true"
          value="70,667">
          Annualised Mixed Emultion
          <span class="text-brand-orange">(L)</span>
        </StaticInput>
        <StaticInput
          bind:value={annualCost}
          classList="mb-2"
          name="annual-cost"
          disabled="true"
          value="15,000">
          Annual cost
          <span class="text-brand-orange">({selectedCurrency.symbol})</span>
        </StaticInput>
        <div class="outline-highlight | mt-6">
          <StaticInput
            bind:value={rocolCostPerIbc}
            error={errors.rocolCostPerIbc}
            classList="mb-2 bg-gray-200"
            name="rocol-cost-per-ibc">
            ROCOL Cost per Unit
            <span class="text-brand-orange">({selectedCurrency.symbol} / Unit)</span>
          </StaticInput>
         <StaticInput
            bind:value={rocolDilutionRatio}
            error={errors.rocolDilutionRatio}
            classList="mb-2 bg-gray-200"
            name="rocol-cost-per-ibc">
            ROCOL Dilution Ratio
            <span class="text-brand-orange">(X:1)</span>
          </StaticInput>
        </div>
      </div>
      <div class="flex justify-end">
        <button
          class="font-bold text-brand-orange uppercase tracking-wider
          antialiased inline-flex leading-none items-center px-4 py-3
          border-brand-orange rounded-lg border text-xs"
          on:click={() => step.update(() => 1)}>
          <Restart />
          <span class="pl-2">Restart</span>
        </button>
        <button
          class="font-bold text-white bg-brand-orange uppercase tracking-wider
          antialiased inline-flex leading-none items-center px-4 py-3 rounded-lg
          text-xs ml-4 whitespace-no-wrap"
          on:click={calculate}>
          Calculate
        </button>
      </div>
    </div>
    <div class="md:w-7/12">
      <div class="p-8 flex items-center border-t-4 border-brand-orange w-full">
        <div
          style={`width: 122px; height: 82px; background-image:
          url('${chosenProduct.product.images[0]}');`}
          class="bg-contain bg-no-repeat bg-center mx-auto" />
        <div class="flex-1">
          <h3
            class="text-brand-orange tracking-widest uppercase text-xs font-bold">
            Your chosen product
          </h3>
          <span class="text-xl font-bold mb-2 block">{chosenProduct.product.title}</span>
          <p>
            {@html chosenProduct.product.description}
          </p>
        </div>
      </div>
      <div class="border-t border-gray-200">
        <Tabs>
          <div class="bg-gray-100 flex justify-center" slot="tab-headings">
            <div class="flex justify-center mx-auto border-l border-gray-200">
              <TabHeading
                current={currentTab === 0}
                on:click={() => (currentTab = 0)}>
                Tooling
              </TabHeading>
              <TabHeading
                current={currentTab === 1}
                on:click={() => (currentTab = 1)}>
                Fluids
              </TabHeading>
              <TabHeading
                current={currentTab === 2}
                on:click={() => (currentTab = 2)}>
                Total
              </TabHeading>
            </div>
          </div>
          <svelte:component this={tabs[currentTab]} {data} hidePercentage={chosenProduct.reichert_wear_percentage} selectedCurrency={selectedCurrency} />
        </Tabs>
      </div>
    </div>
  </div>
</Step>
